import styled from 'styled-components';

import { Columns, Column } from 'components/columns';
import { Field, Input, Radio, RadioGroup } from 'components/formik';

import { TYPE } from '../../utils/enums';
import SecretKey from '../common/SecretKey';

const FormGeneral = () => (
  <StyledForm>
    <Field
      autoFocus
      name="title"
      label="App Title"
      placeholder="Title"
      component={Input}
    />
    <RadioGroup name="type" label="Type">
      <Field
        name="type"
        component={Radio}
        value={TYPE.APP}
        label="Full Application"
        description="App is visible inside the Commerce7 interface and may also integrate with other software."
      />
      <Field
        name="type"
        component={Radio}
        value={TYPE.INTEGRATION}
        label="Integration"
        description="App is not visible inside the Commerce7 interface and solely integrates with other software."
      />
      <Field
        name="type"
        component={Radio}
        value={TYPE.PRIVATE}
        label="Private App or Integration"
        description="App is not publicly available on the App Store. Grant access to specific clients."
      />
    </RadioGroup>
    <SecretKey description="Create a key for your app. Make sure you save it as you won't be able to view it later." />
    <Columns>
      <Column span={6}>
        <Field name="developerName" label="Company Name" component={Input} />
      </Column>
      <Column span={6}>
        <Field
          name="developerEmail"
          label="Contact Email"
          type="email"
          description="Used for app notifications and billing"
          component={Input}
        />
      </Column>
    </Columns>
  </StyledForm>
);

const StyledForm = styled.div`
  margin-top: 40px;
`;

export default FormGeneral;
